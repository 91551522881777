<template>
  <div class="row">
    <div
      class="col-12 col-md-6 mb-3"
      v-for="item in itemsFilter"
      :key="item.id">
      <DetailOrderPayableAvailableCard
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :item="item"
        @updated="$apollo.queries.items.refetch()">
      </DetailOrderPayableAvailableCard>
    </div>
  </div>
</template>

<script>
import { LIST_ORDER_PAYABLE } from './graph'
import DetailOrderPayableAvailableCard from './DetailOrderPayableAvailableCard.vue'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_ORDER_PAYABLE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              contactId: this.contactId
            }
          }
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    itemsFilter () {
      return this.items.filter(v => v.orders.find(o => o.remaining > 0))
    }
  },
  methods: {
    refetchList () {
      this.$apollo.queries.items.refetch()
    }
  },
  components: {
    DetailOrderPayableAvailableCard
  }
}
</script>

<style lang="css" scoped>
</style>
