import { render, staticRenderFns } from "./DetailOrderDiscountInput.vue?vue&type=template&id=e25c0ddc&scoped=true&"
import script from "./DetailOrderDiscountInput.vue?vue&type=script&lang=js&"
export * from "./DetailOrderDiscountInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e25c0ddc",
  null
  
)

export default component.exports