import gql from 'graphql-tag'


const detailResponse = `
  id type name
  qty price totalPrice
  externalDocId externalDoc {id type code}
  withholdingType withholdingTopic
  withholdingName withholdingRemark
  withholdingRate withholdingPrice
  parentId parent {
    id docId doc {id type code name}
  }
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  children {
    id type
    docId doc {id type code}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {
    id docId
  }
}`

export const LIST_ORDER_PAYABLE = (templateType) => gql`query LIST_ORDER_PAYABLE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}Payable (docType: $docType, q: $q) {
    id type code name remark billedAt
    orders {
      id total paid pending remaining
      account {
        id code name
      }
    }
  }
}`

export const LIST_ORDER_CREDIT_NOTE = (templateType) => gql`query LIST_ORDER_CREDIT_NOTE ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableCreditNote (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice remaining
    creditNote {
      id type code name contactId
      approvedBy closedBy
    }
  }
}`

export const LIST_ORDER_DEPOSIT = (templateType) => gql`query LIST_ORDER_DEPOSIT ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableDeposit (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice remaining
    deposit {
      id type code name contactId
      approvedBy closedBy
    }
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  order: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_PAYABLE = (templateType) => gql`mutation CREATE_ORDER_PAYABLE ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  order: createDoc${templateType}OrderPayable (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id docId parentId
  }
}`

export const CREATE_ORDER_CREDIT_NOTE = (templateType) => gql`mutation CREATE_ORDER_CREDIT_NOTE ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  order: createDoc${templateType}OrderCreditNote (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id docId
  }
}`

export const CREATE_ORDER_DEPOSIT = (templateType) => gql`mutation CREATE_ORDER_DEPOSIT ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  order: createDoc${templateType}OrderDeposit (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id docId
  }
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  order: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id docId}
}`

export const LIST_ORDER_CONFIG = (templateType) => gql`query LIST_ORDER_CONFIG ($docType: String!, $contactId: Int!) {
  configs: listDoc${templateType}OrderConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault
    options
  }
}`

export const CREATE_ORDER_WITHHOLDING_PRICE = (templateType) => gql`mutation CREATE_ORDER_WITHHOLDING_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderWithholdingPriceInput!) {
  order: createDoc${templateType}OrderWithholdingPrice (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const UPDATE_ORDER_WITHHOLDING_PRICE = (templateType) => gql`mutation UPDATE_ORDER_WITHHOLDING_PRICE ($docType: String!, $docId: Int!, $orderId: Int!, $input: Doc${templateType}OrderWithholdingPriceUpdateInput!) {
  order: updateDoc${templateType}OrderWithholdingPrice (docType: $docType, docId: $docId, orderId: $orderId, input: $input) {
    id docId parentId
  }
}`

export const RECREATE_WITHHOLDING = (templateType) => gql`mutation RECREATE_WITHHOLDING ($docType: String!, $docId: Int!) {
  doc: reCreateDoc${templateType}Withholding (docType: $docType, docId: $docId) {id}
}`
